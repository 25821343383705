<template>
  <div class="about">
    <TabView :lazy="true" @tab-change="processTab" v-model:activeIndex="active_tab">
      <TabPanel header="Terms And Conditions">
        <h2 style="text-align: left;"><strong>PRIVACY POLICY</strong></h2>
        <p style="text-align: left;">Please carefully read the following terms of use before using this website. By using this website, you signify your consent to the terms of this agreement (Agreement). This website is owned and operated by OPTEL and may be used by you only for personal and non-commercial purposes. You are not authorized to copy, change, adapt, rearrange, reformulate, or decrypt the content of this site, in any way whatsoever.</p>
        <p style="text-align: left;">The materials contained on or accessible through this website (including, without limitation, digital images, videos, products and services offered by OPTEL) shall not be used, redistributed, broadcast, published, copied to any other media, or reproduced for any purpose other than for personal, educational, and non-commercial purposes without the prior written consent of OPTEL. These materials must not be altered, distorted, or used in any way that is detrimental to OPTEL’s image or reputation.</p>
        <p>&nbsp;</p>
        <h2 style="text-align: left;"><strong>CONFIDENTIALITY</strong></h2>
        <p style="text-align: left;">We will not sell, share, or rent your personal information to any third party or use your email address for unsolicited mail. Any emails sent by OPTEL will only be in connection with the provision of agreed services and products.</p>
        <p style="text-align: left;">Customer records are regarded as confidential and therefore will not be divulged to any third party, other than if legally required to do so to the appropriate authorities. Customers have the right to request sight of, and copies of any and all customer records we keep, provided we are given reasonable notice of such a request. Where appropriate, we shall issue customers with appropriate written information, handouts or copies of records as part of an agreed contract, for the benefit of both parties.</p>
        <p>&nbsp;</p>
        <h2 style="text-align: left;"><strong>PRIVACY STATEMENT</strong></h2>
        <p style="text-align: left;">We are committed to protecting your privacy. Authorized employees within the company use information collected from individual customers on a need-to-know basis only. We constantly review our systems and data to ensure the best possible service to our customers.</p>
        <p>&nbsp;</p>
        <h2 style="text-align: left;"><strong>DISCLAIMER</strong></h2>
        <h3 style="text-align: left;"><strong>EXCLUSIONS AND LIMITATIONS</strong></h3>
        <p style="text-align: left;">The information on this website is provided on an “as is” basis. To the fullest extent permitted by law, OPTEL:</p>
        <ul style="text-align: left;">
        <li aria-level="1">excludes all representations and warranties relating to this website and its contents or which is or may be provided by any affiliates or any other third party, including in relation to any inaccuracies or omissions in this website and/or OPTEL literature; and</li>
        <li aria-level="1">excludes all liability for damages arising from or in connection with your use of this website. This includes, without limitation, direct loss, loss of business or profits (whether or not the loss of such profits was foreseeable, arose in the normal course of things or you have advised OPTEL of the possibility of such potential loss), damage caused to your computer, computer software, systems and programs and the data thereon or any other direct or indirect, consequential and incidental damages.</li>
        </ul>
        <p style="text-align: left;">OPTEL does not, however, exclude liability for death or personal injury caused by its negligence. The above exclusions and limitations apply only to the extent permitted by law. None of your statutory rights as a consumer are affected.</p>
        <p>&nbsp;</p>
        <h2 style="text-align: left;"><strong>PRIVACY POLICY</strong></h2>
        <h3 style="text-align: left;"><strong>1      INTRODUCTION</strong></h3>
        <p style="text-align: left;">1.1      OPTEL GROUP, a company (with registered number 1170998646) whose registered office is at 2680 boulevard du Parc Technologique, Québec, QC CANADA G1P 4S6 (“OPTEL” or “we” or “us”) is strongly committed to privacy issues, and this privacy statement details our approach on such issues. By using these websites (.<a href="http://www.optelgroup.com">www.optelgroup.com</a>; <a href="http://behaviori.com/en/">www.behaviori.com</a>) (“Site”) and submitting any personal data, you agree to the use by us of such data in accordance with this privacy statement. If you have questions on privacy issues, please email us at <a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="167f78707956796662737a71647963663875797b38">[email&#160;protected]</a></p>
        <p style="text-align: left;">This policy (together with our terms of use [https://www.optelpharmaceutical.com/terms-conditions/] and any other documents referred to on it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.</p>
        <p style="text-align: left;">For the purposes of the Data Protection Act 1998 (the Act), the data processor is OPTEL GROUP and the data controllers are GeoTraceability Limited, part of OPTEL GROUP, and Verify Brand, part of OPTEL GROUP.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong>2      DATA COLLECTION</strong></h3>
        <p style="text-align: left;">2.1   If you contact us, we may keep a record of that correspondence.</p>
        <p style="text-align: left;">2.2   We may also keep:</p>
        <p style="text-align: left;">2.2.1   details of transactions you carry out through the Site;</p>
        <p style="text-align: left;">2.2.2   details of your visits to the Site including, but not limited to, traffic data, location data, weblogs;</p>
        <p style="text-align: left;">2.2.3   other communication data; and</p>
        <p style="text-align: left;">2.2.4   information about your computer, including your IP address, operating system and browser type</p>
        <ul style="text-align: left;">
        <li>to send you marketing communications, including via email and SMS in compliance with applicable laws, that we believe may be of interest to you;</li>
        <li>to personalize your experience on the Sites by presenting products and offers tailored to you;</li>
        <li>to allow you to participate in sweepstakes, contests and similar promotions and to administer these activities. Some of these activities have additional rules, which could contain additional information about how we use and disclose your personal information, so we suggest that you read these rules carefully.</li>
        </ul>
        <p style="text-align: left;">2.3   Other than collection of the data specified in clause 2.2 above, we only collect identifiable personal data or information that is specifically and voluntarily provided by a visitor to the Site. We receive limited identifiable information, such as name, title, company address, email address, and telephone and fax numbers, from Site visitors.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong>3      USE OF DATA</strong></h3>
        <p style="text-align: left;">3.1   Typically, identifying information is collected to:</p>
        <p style="text-align: left;">3.3.1   register for certain areas of the Site;</p>
        <p style="text-align: left;">3.3.2   inquire for further information; and</p>
        <p style="text-align: left;">3.3.3   distribute requested materials.</p>
        <p style="text-align: left;">3.2   In many instances, when you provide personal data to us, we will use it for the purposes as stated at the point of collection (or as is obvious from the context of collection).</p>
        <p style="text-align: left;">3.3      We also use information held about you in the following ways:</p>
        <ul style="text-align: left;">
        <li>to carry out our obligations arising from any contracts entered into between you and us and to provide you with the information, products and services that you request from us;</li>
        <li>to provide you with information about other goods and services we offer that are similar to those that you have already purchased or inquired about (see the “Marketing” section below as well);</li>
        <li>to notify you about changes to our service;</li>
        <li>to ensure that content from our Site is presented in the most effective manner for you and for your computer;</li>
        <li>to administer our Site and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;</li>
        <li>to allow you to participate in interactive features of our service, when you choose to do so;</li>
        <li>as part of our efforts to keep our Site safe and secure;</li>
        <li>to measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you;</li>
        <li>  to make suggestions and recommendations to you and other users of our Site about goods or services that may interest you or them.</li>
        </ul>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong>4    INFORMATION ABOUT OTHER INDIVIDUALS</strong></h3>
        <p style="text-align: left;">If you give us information on behalf of someone else, you confirm that the other person has appointed you to act on his/her behalf and has agreed that you can:</p>
        <ul style="text-align: left;">
        <li>give consent on his/her behalf to the processing of his/her personal data;</li>
        <li>receive on his/her behalf any data protection notices; and</li>
        <li>give consent to the transfer of his/her personal data as contemplated in this privacy policy.</li>
        </ul>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong>5      MARKETING</strong></h3>
        <p style="text-align: left;">5.1   We may use your data to contact you with information about our business, services and events, and other information which may be of interest to you. It is in our legitimate business interests to provide you with these communications, which we have determined are relevant to your interests; however, if you prefer not to receive these communications, you may unsubscribe at any time (see clause 5.3 below).</p>
        <p style="text-align: left;">3.2   If you have given permission, we may share your personal data with organizations that are our business partners and we or they may contact you (unless you have asked us or them not to do so) by mail, telephone, SMS, text/picture/video message, fax, email, etc., about their products, services, promotions, special offers, charitable causes, etc., that may be of interest to you. If you prefer not to receive any further direct communications of this nature from us or our business partners, you can opt out at any time. See clause 5.3 below and the “Your Rights” section below.</p>
        <p style="text-align: left;">5.3   Should you subsequently choose to unsubscribe from mailing lists or any registrations, we will provide instructions, on the appropriate webpage or in communications to you, or you may contact us. See “Your Rights” and “How to Contact Us” sections below.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong>6      DISCLOSURE TO THIRD PARTIES</strong></h3>
        <p style="text-align: left;">6.1   Personal data that has been collected by, or submitted to, us may be disclosed to other members of OPTEL GROUP.</p>
        <p style="text-align: left;">6.2   We may disclose your personal information to third parties:</p>
        <p style="text-align: left;">6.2.1   in the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets;</p>
        <p style="text-align: left;">6.2.2   if our business or substantially all of our assets are acquired by a third party, in which case personal data held by us about our customers will be one of the transferred assets; and</p>
        <p style="text-align: left;">6.2.3   if we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply the Agreement. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong>7      SECURITY</strong></h3>
        <p style="text-align: left;">7.1   We have implemented generally accepted standards of technology and operational security in order to protect personally identifiable data and information from loss, misuse, alteration or destruction. In particular, we ensure that all appropriate confidentiality obligations and technical and organizational security measures are in place to prevent any unauthorized or unlawful disclosure or processing of such information and data and the accidental loss or destruction of or damage to such information and data. Only authorized personnel are provided access to personally identifiable information and these employees have agreed to ensure confidentiality of this information. Our policy is to use secure socket layer technology for the protection of credit card information submitted through web forms.</p>
        <p style="text-align: left;">7.2   Where we have given you (or where you have chosen) a password that enables you to access certain parts of our Site, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.</p>
        <p style="text-align: left;">7.2   Unfortunately, the transmission of information via the Internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our Site; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong>8          DATA RETENTION</strong></h3>
        <p style="text-align: left;">8.1   The personal data you submit to us will only be retained for as long as is required for the purposes for which it was collected or as required by law.</p>
        <p style="text-align: left;">8.2   For example, contact information about visitors (such as information generated through registration for access to areas on the Site) will be kept as long as the information is required to completely service the contact request or until a user requests that we delete that information. Mailing list information, discussion posts and email are kept for only the period of time considered reasonable to facilitate the visitor’s requests.</p>
        <p style="text-align: left;">8.3  We use the following criteria to determine how long we need to keep your data:</p>
        <ul style="text-align: left;">
        <li>whether the purpose has been fulfilled, e.g., your agreement with us for provision of services has terminated</li>
        <li>to comply with company recordkeeping obligations</li>
        <li>to represent our interactions with you in the event that a complaint is made against us.</li>
        </ul>
        <p style="text-align: left;">8.4   Where we determine that your data needs to be kept for a longer period of time, it will be retained in a secure environment and access to it will be restricted to only those personnel who need to know it, e.g., to deal with a complaint or follow up on further enforcement proceedings.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong>9        WHERE WE STORE YOUR PERSONAL DATA</strong></h3>
        <p style="text-align: left;">10.1 The data that we collect from you may be transferred to, and stored at, a destination outside the European Economic Area (“EEA”). It may also be processed by staff operating outside the EEA who work for us or for one of our suppliers. Such staff may be engaged in provision of services and benefits, the fulfillment of your order, the processing of your payment details and the provision of support services, among other activities. By submitting your personal data, you agree to this transfer, storing and/or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong>10     YOUR RIGHTS</strong></h3>
        <p style="text-align: left;">10.1    Right to ask us to stop contacting you with direct marketing. Even if you have accepted the processing of your personal data for marketing purposes (by ticking the relevant box), you have the right to ask us to stop processing your personal data for such purposes. You may also request a modification in the means we use to contact you (e.g., by email or by telephone).</p>
        <p style="text-align: left;">10.2 Right to request a copy of your information. You can request a copy of information you have submitted to us through the Site and that we hold about you (this is known as a Subject Access Request). If you would like a copy of some or all of it, please contact us and let us know the information you want a copy of, including any account or reference numbers, if you have them. Any Subject Access Request may be subject to a reasonable fee to cover the cost of providing you with details of the information we hold about you. See the “How to Contact Us” section below to get our contact details.</p>
        <p style="text-align: left;">10.3 Right to correct any mistakes in your information. When personally identifiable information is retained, we do not assume responsibility for verifying the ongoing accuracy of the content of personal information. When practically possible, if we are informed that any personal data collected through the Site is no longer accurate, we will make appropriate corrections based on the updated information provided by you. You can update personal data you have submitted through the Site via the original registration page, or you can ask us to correct any mistakes in your information. If you would like to do this, please contact us (see the “How to Contact Us” section below) and let us know the information that is incorrect and the information you want it replaced with.</p>
        <p style="text-align: left;">10.4 Right to request we cease processing your information. You may request that we cease processing your personal data. If you make such a request, we shall retain only the amount of personal data pertaining to you that is necessary to ensure that no further processing of your personal data takes place.</p>
        <p style="text-align: left;">10.5 Right to request deletion of your information. You may ask us to erase all your personal data (also known as the “Right to Be Forgotten”) in the following circumstances:</p>
        <p style="text-align: left;">– it is no longer necessary for us to hold that personal data with respect to the purpose for which it was originally collected or processed;</p>
        <p style="text-align: left;">– you wish to withdraw your consent to us holding and processing your personal data;</p>
        <p style="text-align: left;">– you object to us holding and processing your personal data (and there is no overriding legitimate interest to allow us to continue doing so);</p>
        <p style="text-align: left;">– the personal data has been processed unlawfully; or</p>
        <p style="text-align: left;">– the personal data needs to be erased in order for us to comply with a particular legal obligation.</p>
        <p style="text-align: left;">Unless we have reasonable grounds to refuse to erase your personal data, all requests for erasure shall be complied with.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong>11  IF YOU ARE AN INDIVIDUAL FROM WHOM OUR CLIENTS HAVE COLLECTED DATA, THIS SECTION APPLIES TO YOU:</strong></h3>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong>12   HOW TO CONTACT US</strong></h3>
        <p style="text-align: left;">Questions, comments and requests regarding this privacy policy are welcomed and should be addressed to <a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="7d14131b123d120d0918111a0f12080d531e121053">[email&#160;protected]</a> Please use these contact details to exercise your rights as outlined in the “Your Rights” section above.</p>
        <p>&nbsp;</p>
        <h4 style="text-align: left;"><strong>HOW CAN I ACCESS, CORRECT, AMEND OR REMOVE INFORMATION ABOUT ME?</strong></h4>
        <p style="text-align: left;">If you would like to access, correct, amend, remove or limit the use or disclosure of any personal information about you that has been collected and stored by OPTEL, please notify us at <a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="a1c8cfc7cee1ced1d5c4cdc6d3ced4d18fc2cecc">[email&#160;protected]</a> so that we may consider and respond to your request in accordance with applicable laws.</p>
        <p style="text-align: left;">For your protection, we may only implement requests with respect to the personal information associated with the particular email address that you use to send us your request, and we may need to verify your identity before implementing your request. We will respond to your request within 45 days.</p>
        <p style="text-align: left;">Please note that we may need to retain certain information for recordkeeping purposes and/or to complete any transactions that you began prior to requesting such access, change or deletion.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong>13  COMPLAINTS</strong></h3>
        <p style="text-align: left;">If you have any complaints about the way in which we collect, store and use your information, and these have not been addressed by contacting us first, you can contact the supervisory authority in the United Kingdom, the Information Commissioner’s Office: <a href="https://ico.org.uk/concerns/">https://ico.org.uk/concerns/</a>.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong>14    CHANGES TO THIS PRIVACY POLICY</strong></h3>
        <p style="text-align: left;">We may change this privacy from time to time, and the changes will take effect from the date we post the amended policy on the Site. If you do not agree with the changes, you should not continue to use the Site.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong>15   LINKS TO OTHER WEBSITES</strong></h3>
        <p style="text-align: left;">Our Site may contain links to other websites of interest; however, once you have used these links to leave our Site, you should note that we do not have any control over other websites and their content. Therefore, we cannot be responsible for the protection and privacy of any information that you provide whilst visiting such sites, and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.</p>
      </TabPanel>
      <TabPanel header="Genral Conditions of Use">
        <h2 class="title-1">Last update: August 2021</h2>
        <h2 style="text-align: left;"></h2>
        <h2 style="text-align: left;"><strong>GENERAL CONDITIONS </strong></h2>
        <h3 style="text-align: left;"><strong> USE OF THE SERVICES AND ACCEPTANCE OF THE GENERAL CONDITIONS</strong></h3>
        <p style="text-align: left;">These general conditions (henceforth the “Conditions”) constitute an agreement between party of the first part, Optel Vision Inc. (henceforth “Optel Vision,” “we,” “us,” or “our”), the owner and operator of the mobile applications and associated online services (collectively referred to as the “Services”); and party of the second part, you (henceforth the “User” or “you”), a user of or visitor to the Services, or a person who otherwise uses or accesses the Services. Please read these Conditions carefully before using our Services.</p>
        <p style="text-align: left;">By installing, using or accessing our Services, you agree to be bound by these Conditions, including any additional terms or policies that are included in the Conditions by hyperlink or other means of reference, such as our Privacy Policy. For more information on how OPTEL Vision collects, uses and shares your information, please see our Personal Data Protection Policy. You accept that by using the site, you consent to this information being collected, used and shared (as defined in the Personal Data Protection Policy), including the transfer of this information to the United States and/or other countries for storage, processing and use by Optel Vision. If you do not accept these Conditions, in whole or in part, you may not use the Services.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong> ACCOUNTS</strong></h3>
        <p style="text-align: left;">When you create an account with us, you must complete the sign-up process by providing us with accurate, complete and up-to-date information at all times. Failure to respect this obligation is a violation of the Conditions, and may result in the immediate closure of your account on our Services.</p>
        <p style="text-align: left;">It is strictly forbidden to use an account without the account holder’s authorization. For your username, you may not select the name of another person or entity, a name that cannot be legally used, a name or trademark with copyright held by a person or entity other than yourself without proper authorization, or a name that is offensive, vulgar or obscene.</p>
        <p style="text-align: left;">You are responsible for protecting the password you use to access the Services, and for all activities or actions that take place under your password. You agree to not disclose your password to third parties.</p>
        <p style="text-align: left;">You must notify us as soon as you become aware of any security breach or unauthorized use of your account. We will accept no liability for any loss that you may incur as a result of the fraudulent use of your password or account, whether it is done with or without your knowledge. You may be held liable for our losses, or those incurred by a third party, if someone else uses your account or password.</p>
        <p style="text-align: left;">We endeavor to implement reasonable security measures to protect you from all unauthorized access to your account and personal information. However, we cannot guarantee the security of your account, your content (as defined below) or the personal information you provide, nor can we guarantee that our security measures will prevent “hackers” from illegally accessing the Services or their content. You acknowledge that you provide your personal information at your own risk.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong> DEACTIVATING YOUR ACCOUNT</strong></h3>
        <p style="text-align: left;">You may request that your personal information is deleted by contacting us via the email address <a href="/cdn-cgi/l/email-protection#a2cec7c5c3cee2cdd2d6c7cec5d0cdd7d28cc1cdcf"><span class="__cf_email__" data-cfemail="bbd7dedcdad7fbd4cbcfded7dcc9d4cecb95d8d4d6">[email&#160;protected]</span></a>. Once it has been deactivated, your account and its Content (as defined below, and including all benefits from the Rewards Program) cannot be restored.</p>
        <p style="text-align: left;">We may immediately limit, suspend or deactivate your account for any reason, without prior notice or liability, including but not limited to you violating the Conditions. In particular, Optel Vision may immediately revoke or suspend accounts flagged for repeated copyright infringements.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong> CONTENT</strong></h3>
        <p style="text-align: left;">Our services allow you to submit, publish, display, link, store, share and make available information, text, graphics, video and other materials (henceforth the “Content”). You will retain all of your rights to any Content that you submit, publish or display on or through the Services. You are responsible for protecting these rights. You are responsible for the Content you post on the Services, including its legality, trustworthiness and relevance.</p>
        <p style="text-align: left;">By submitting, publishing or displaying any Content on the Services, you grant us a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use, modify, publicly perform, publicly display, reproduce and distribute this Content, including, without limitation, on and through the Services, as well as promotion and redistribution of all or part of the Services (and derivative works thereof) in any media format and via any media channel. You agree that this license gives us the right to make your Content available to other users of the Service, who may also use your Content in accordance with these Conditions.</p>
        <p style="text-align: left;">You declare and guarantee: (i) that the Content is yours (you are the owner) or you have the right to reproduce and use it, as well as granting us the rights and license to do so in accordance with these Conditions, and (ii) that displaying your Content on or through the Services does not violate the privacy rights, publicity rights, copyrights, trademarks, contractual rights or any other rights of any person.</p>
        <p style="text-align: left;">We have the right to block or delete communications and documents that we consider to be: (a) abusive, defamatory or obscene; (b) fraudulent, deceptive or misleading; (c) in violation of any copyright, trademark or other intellectual property right, or; (d) offensive or otherwise unacceptable in our view, at our sole discretion.</p>
        <p style="text-align: left;">You acknowledge that by providing you with the ability to view and distribute the content generated by users on the Service, we are simply acting as a passive channel for this distribution, and do not undertake any obligation or liability relating to the Content or activity present on the Services.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong> CONDUCT</strong></h3>
        <p style="text-align: left;">In addition to the prohibitions stated in the Conditions, you are forbidden to use the Services:</p>
        <p style="text-align: left;">For illicit purposes;</p>
        <ul style="text-align: left;">
        <li>To solicit third parties to perform or participate in illegal acts;</li>
        <li>To violate any local, international, federal, provincial or state regulation, rule, law or ordinance;</li>
        <li>To infringe or violate our intellectual property rights, or those of others;</li>
        <li>To harass, abuse, insult, harm, defame, slander, demean, intimidate or discriminate on the basis of gender, sexual orientation, religion, ethnic background, age, country of origin, or disability;</li>
        <li>To submit false or misleading information;</li>
        <li>To impersonate another person or to give a false representation of your affiliation with a person or entity, to commit fraud, or to hide (or attempt to hide) your identity;</li>
        <li>To upload or transmit viruses, worms, rootkits or any other type of malicious code that will or may be used in any way that could have an impact on the functionality or operation of the Services;</li>
        <li>To collect or track the personal information of third parties, including account names;</li>
        <li>For the purposes of spamming, phishing, spoofing or pharming;</li>
        <li>For the purposes of commercial solicitation;</li>
        <li>For obscene or immoral purposes;</li>
        <li>To interfere with the correct functioning of the Services; or</li>
        <li>To interfere with or circumvent the Services’ security features, including but not limited to features that prevent or restrict the use or reproduction of any content, or that impose limitations on the use of the Services or their content.</li>
        <li>Furthermore, you may not engage in any of the following prohibited activities:</li>
        <li>Copying, distributing, or disclosing any part of the Services by any means, including, but not limited to automated or non-automated data mining systems;</li>
        <li>The use of any automated system, including, but not limited to “bots”, “collectors”, “offline readers”, etc., to access the Services;</li>
        <li>Attempts to compromise or interfere with the system’s integrity or security, or to decode any transmission sent to or from the servers running the Services;</li>
        <li>Taking any action that imposes, or may impose, an unreasonable or disproportionate load on the Services’ infrastructure, at our sole discretion; or</li>
        <li>Accessing any content on the Services through technologies or means other than those intended or authorized by the Services.</li>
        </ul>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong> INTELLECTUAL PROPERTY</strong></h3>
        <p style="text-align: left;">Except in the case of works presented or used under license or hypertext links displayed on the Services, Optel Vision is the exclusive holder of the intellectual property rights to all content on the Services, including, but not limited to text, images, graphics or code, which are otherwise protected by copyright, trademark and other intellectual property rights under Canadian and foreign laws, as well as international conventions. These Conditions do not grant you a license to use the intellectual property rights of Optel Vision or any affiliated companies. The Optel Vision name and logo are registered trademarks of Optel Vision, and may not be copied, imitated or used, in whole or in part, without prior written authorization from Optel Vision. Furthermore, all page headers, customized graphics, button icons and scripts are trademarks and trade dress of Optel Vision, and may not be copied, imitated or used, in whole or in part, without prior written authorization from Optel Vision. Additionally, you agree not to modify or remove any intellectual property notices from materials downloaded from the Services.</p>
        <p style="text-align: left;">If a third party trademark is displayed on the Services via a hyperlink or other means, this will not be understood as a “use” of this trademark within the meaning of the Law on Trademarks, nor will it be understood that Optel Vision is claiming an affiliation or association with the owner of that trademark.</p>
        <p style="text-align: left;">For the purposes of browsing the Services, Optel Vision grants the user a limited license that allows them to display on his or her computer, telephone or other device, or to print, download and use the HTML underlying the Services, as well as the text, audio clips, video clips and any other content they may have access to, but only for non-commercial, personal or educational purposes, all on the condition that the content is not modified.</p>
        <p style="text-align: left;">Other than the aforementioned, no other use of Optel Vision’s intellectual property rights may be authorized, unless written consent is obtained from Optel Vision in advance.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong> THIRD PARTIES</strong></h3>
        <p style="text-align: left;">Content, offers and services from third parties that are made available on the Services are designed and offered directly by the third party, and are neither owned nor operated by Optel Vision. Searching for or purchasing such content, offers and services means you acknowledge that you are dealing directly with the third party, and not with Optel Vision. You are not required to use or interact with any third party that appears on the Services.</p>
        <p style="text-align: left;">Optel Vision has no control over the content, privacy policies or practices of any third party service or website, and assumes no liability in this regard. Furthermore, you further acknowledge and accept that Optel Vision cannot be directly or indirectly held responsible for any damage or loss caused or allegedly caused, by or in connection with use of or trust in any such content, product or service that is available on such sites or services, or is accessible through them. Optel Vision is not responsible for: (i) the availability or accuracy of these sites or resources, or (ii) the content, products or services available on these sites or resources. The presence of a link to these sites and resources does not imply our endorsement of them. You acknowledge full responsibility and assume all risks that may arise from using these sites or resources.</p>
        <p style="text-align: left;">We strongly advise that you read the general conditions and privacy policies of any third-party site or service you visit.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong> FEEDBACK</strong></h3>
        <p style="text-align: left;">Optel Vision has not agreed and not does it agree to treat any Feedback (as defined below) that you may provide to Optel Vision as confidential. Nothing in the present agreement, or in the relationship between the parties that arises from or is linked to this agreement, limits Optel Vision’s right to use, disclose, publish, keep secret, otherwise exploit or benefit from your Feedback, without giving you compensation or credit. (The term “Feedback” refers to any suggestion, comment or idea that aims to improve or modify any Optel Vision Service in any way).</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong> TERMINATION</strong></h3>
        <p style="text-align: left;">Either of the parties may terminate this agreement at any time by notifying the other party in writing.</p>
        <p style="text-align: left;">We may immediately limit, suspend or deactivate your account for any reason, without prior notice or liability, including but not limited to you violating the Conditions. In the event of termination, your access and right to use the Services will cease immediately.</p>
        <p style="text-align: left;">The following provisions of the Conditions will remain in force for a period of three (3) years following termination or expiry of the Conditions: Compensation, Limitation of Liability, Disclaimer and Non-Waiver of Rights, and Miscellaneous.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong> COMPENSATION</strong></h3>
        <p style="text-align: left;">You agree to compensate, defend and hold harmless Optel Vision, its affiliates, managers, administrators, directors, representatives, employees, contractors, licensors and licensees, suppliers and agents, from and against all claims, losses, damages, costs, liabilities, actions or demands, including legal and out-of-court expenses, arising from (a) your use of the Service; (b) your violation of any of these Conditions; (c) your publications or uploads on the Service; and (d) any activity linked to your account. This includes all negligent or unlawful conduct on your part, as well as access made to the Services by any natural or legal person using your account, if such access is obtained by illegal or fraudulent means.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong> LIMITATION OF LIABILITY</strong></h3>
        <p style="text-align: left;">Optel Vision offers no guarantee or representation of any kind regarding the Services. All allegedly applicable guaranties and general conditions are excluded to the extent permitted by the law. You use the Services at your own risk. The Services are provided “as is” and are subject to availability. The Services are provided without any kind of guarantee, neither express nor implied, including, but not limited to implied warranties of merchantability, suitability for a particular purpose, non-infringement or course of performance, except as provided for by Canadian provincial laws. In such cases, provincial law applies to the extent necessary.</p>
        <p style="text-align: left;">Optel Vision, its administrators, employees, partners, agents, suppliers, subsidiaries, affiliates and licensors do not guarantee that (a) the Services will operate without interruption, and will be secure and available at all times and in all places; (b) errors or faults will be corrected; (c) the Services are free from viruses or other harmful components; or that (d) using the Services will result in your requirements being met.</p>
        <p style="text-align: left;">Optel Vision, its administrators, employees, partners, agents, suppliers or affiliates may not be held responsible for any loss or damage, whether direct or indirect, incidental, special, consequential or punitive, including but not limited to economic loss, loss of or damage to property, data or electronic media, business capital or other intangible loss, resulting from (i) your use of or access to the Services; (ii) your inability to access or use the Services; (iii) any conduct or content of any third party on or linked to the Services; (iv) any content obtained from or through the Services; (v) unauthorized access to use or modify your transmissions or content; and (vi) information contained on the Site.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong> DISCLAIMER AND NON-WAIVER OF RIGHTS</strong></h3>
        <p style="text-align: left;">If you violate any of these Conditions and Optel Vision chooses to either not act immediately, or not at all, Optel Vision may still exercise all rights and remedies at a later date, or in any other situation where you violate the Conditions. Optel Vision does not waive any of its rights. Optel Vision shall not be held responsible for any alleged violation of these Conditions that results from circumstances beyond its control. Only the parties stated in these Conditions have performance rights.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong> MISCELLANEOUS</strong></h3>
        <p style="text-align: left;">These Conditions are exclusively governed by the laws of the province of Quebec, without reference to principles of conflict of laws that would be applicable to the substantive law of any other jurisdiction regarding the rights or obligations of the parties. The parties accept the personal and exclusive jurisdiction of the provincial and federal courts of the judicial district of Quebec in the Canadian province of Quebec.</p>
        <p style="text-align: left;">If any provision of these Conditions is found to be invalid or unenforceable by a court of competent jurisdiction, all other provisions of the Conditions will remain in force.</p>
        <p style="text-align: left;">These Conditions constitute the entire agreement between you and us with regards to our Services, and override any prior agreements, oral or otherwise, regarding the Services.</p>
        <p style="text-align: left;">You may not assign, sub-license or transfer any of your rights under these Conditions.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong> MODIFICATIONS</strong></h3>
        <p style="text-align: left;">We reserve the right, at our sole discretion, to modify or replace these Conditions at any time, by publishing the “Updated Conditions”. The Updated Conditions will come into force from the moment they are published, or at a later date specified in the Updated Conditions, and will apply to your use of the Services from that moment. Unless Optel Vision makes a modification for legal or administrative reasons, if the change is significant, we will take all possible measures to provide at least 30 days’ notice before the Updated Conditions come into force. The notion of “significant change” will be defined at our sole discretion.</p>
        <p style="text-align: left;">By continuing to access or use our Services after these changes come into force, you agree to be bound by the Updated Conditions. If you do not accept the Updated Conditions, in whole or in part, you may not use the Services.</p>
        <p>&nbsp;</p>
        <h3 style="text-align: left;"><strong> COMMUNICATION</strong></h3>
        <p style="text-align: left;">If you have any questions regarding these Conditions or for any notifications, please contact us at the following address:</p>
        <p style="text-align: left;"><strong>OPTEL Vision Inc.</strong></p>
        <p style="text-align: left;">By email:<br />
        <a href="/cdn-cgi/l/email-protection#7f0c0a0f0f100d0b3f100f0b1a13180d100a0f511c1012"><span class="__cf_email__" data-cfemail="bbc8cecbcbd4c9cffbd4cbcfded7dcc9d4cecb95d8d4d6">[email&#160;protected]</span></a></p>
        <p style="text-align: left;">By mail:</p>
        <p style="text-align: left;">2680, boul. du Parc Technologique<br />
        Québec (QC) G1P 4S6<br />
        CANADA</p>
      </TabPanel>
      <TabPanel header="Privacy Policy">
          <h2 class="title-1">Last update: August 2021</h2>
          <h2 style="text-align: left;"></h2>
          <h2 style="text-align: left;"><strong>PRIVACY POLICY </strong></h2>
          <p style="text-align: left;">Optel Vision Inc. (henceforth “Optel”, “we” or “our”) is committed to protecting the confidentiality and security of your information. This privacy policy (henceforth the “Policy”) describes how we collect, use and disclose your personal information, and how this information can be accessed and corrected if necessary. This Policy also explains how cookies are used in our Services.</p>
          <p style="text-align: left;">By using the OPTEL GEOTRACE application (collectively, the “Services”), contacting us or agreeing to receive emails from Optel, you agree to the general conditions of the Policy.</p>
          <p style="text-align: left;">The Policy does not extend to sites/services operated by third parties. Optel is therefore not responsible for their policies, procedures and practices regarding the issue of confidentiality.</p>
          <p>&nbsp;</p>
          <h3 style="text-align: left;"><strong>COLLECTION OF INFORMATION</strong></h3>
          <p style="text-align: left;">The types of Personal Data collected by the OPTEL GEOTRACE Application include geographical location; precise location authorization; camera authorization.</p>
          <p style="text-align: left;">We collect your personal information in many ways, in particular when you enter information through our Services, use the OPTEL GEOTRACE application, contact us by email, or send messages to our chatbots, etc. Depending on the circumstances, this personal information may include your first name, last name, email address, password, etc. When using the OPTEL GEOTRACE application on a smartphone or any other portable device, we request your explicit consent to collect certain information, including your location data for the purposes of using the application for to track the geolocation of contaminated soil. You may use your account settings and tools to withdraw your consent at any time, including by ceasing to use a function, deleting access to a third-party service, or deleting your data or account. We use this information to authenticate and quantify your activity. If you decide not to disclose the personal information we request from you, you may not be able to access certain parts of the Services.</p>
          <p style="text-align: left;">The information entered will not be disclosed. However, this information may be recorded for the purpose of optimizing the user experience on the application.</p>
          <p style="text-align: left;">We primarily process personal information because it is necessary for the provision of the Services and it is done in our legitimate interest, as explained in more detail in the section “Use and Disclosure of Information”. We may also process personal information with your consent, where appropriate.</p>
          <p>&nbsp;</p>
          <h3 style="text-align: left;"><strong>USE AND DISCLOSURE OF INFORMATION</strong></h3>
          <p style="text-align: left;">We take measures with the aim of ensuring that your personal information can only be accessed by employees who need to it in order to perform their duties. We mainly use and disclose your personal information:</p>
          <p style="text-align: left;">To operate, maintain, improve and provide all features of the Services, provide the services and information you request, respond to comments and questions, and provide assistance to users of the Services;</p>
          <p style="text-align: left;">To understand and analyze usage trends and the preferences of our users, improve the Services, and develop new products, services, features and functions;</p>
          <p style="text-align: left;">To send you communications;</p>
          <p style="text-align: left;">For the possible transfer of your personal information in the event of a change of ownership, sale, merger, liquidation, restructuring or acquisition of Optel, in whole or in part; or</p>
          <p style="text-align: left;">For all other purposes authorized or required by the law.</p>
          <p style="text-align: left;">When we disclose your personal information to third parties, we take reasonable measures to ensure that the rules stated in this Policy are respected, and that these third parties provide sufficient guarantees that appropriate technical and organizational measures are implemented.</p>
          <p style="text-align: left;">Finally, we may make certain automatically collected, gathered, or otherwise personally non-identifiable information available to third parties for various purposes, including (i) compliance with various reporting obligations; (ii) for marketing or advertising purposes; or (iii) to help these parties understand your interests, habits and usage trends for certain programs, content, services, and/or features that are available through the Services.</p>
          <p>&nbsp;</p>
          <h3 style="text-align: left;"><strong>STORING INFORMATION</strong></h3>
          <p style="text-align: left;">Your personal information may be stored and processed in any country where we have facilities, or where we use third party service providers. By using the Services, you consent to the transfer of information to countries other than your country of residence, where data protection rules may differ from those in your country. Although this information is located outside of Canada, it is subject to the laws of the country in which it is held, and may be subject to disclosure to the governments, courts, law enforcement or regulatory agencies of this other country, under its laws. However, our practices regarding your personal information will continue to be governed by this Policy at all times. In addition, where applicable, we will comply with the requirements of the GDPR, which ensures a suitable level of protection for the transfer of personal information from the EU/EEA to a third country.</p>
          <p style="text-align: left;">Optel has implemented various physical, administrative and technical measures aimed at protecting the privacy and security of the personal information under our control. However, there are no absolute security measures, and no total guarantees can be offered. If you think your personal information has been compromised, please contact us as indicated in the “Contact” section. If we discover that the security systems have been violated, we will inform you and the authorities of this violation, in accordance with the legal provisions in force.</p>
          <p style="text-align: left;">We will only store your personal information for a reasonable period that is necessary to fulfill the purposes stated in this Policy, and to comply with our legal and regulatory obligations. If you would like more information about how long your personal information will be stored, please contact us as indicated in the “Contact” section.</p>
          <p>&nbsp;</p>
          <h3 style="text-align: left;"><strong>RIGHTS RELATING TO PERSONAL INFORMATION</strong></h3>
          <p style="text-align: left;">You may access the personal information we hold about you and request any necessary corrections, as permitted or required by law, by sending your request in writing and providing an identification document. However, to ensure that the personal information we store about you is accurate and up-to-date, we kindly ask you to immediately notify us of any changes to your personal information.</p>
          <p style="text-align: left;">The User’s personal data may be used by Optel for legal purposes in court, or as part of steps that may lead to legal action due to improper use of the Application. The User is aware of the fact that Optel may have to disclose Personal Data if requested by the public authorities.</p>
          <p>&nbsp;</p>
          <h3 style="text-align: left;"><strong>UPDATE</strong></h3>
          <p style="text-align: left;">We encourage you to regularly check this page in order to stay informed of any changes to this Policy, which may be updated from time to time. If we modify the Policy, we will make it available through the Services and indicate the date of the last revision, while complying with the legal requirements in force. Continuing to use the Services after the revised Policy comes into effect indicates that you have read, understood and agreed to the current version of the Policy.</p>
          <p>&nbsp;</p>
          <h3 style="text-align: left;"><strong>CAMERA AUTHORIZATION</strong></h3>
          <p style="text-align: left;">Access to the camera to record images and videos with the device.</p>
          <p>&nbsp;</p>
          <h3 style="text-align: left;"><strong>LOCALIZATION AUTHORIZATION</strong></h3>
          <p style="text-align: left;">Localization is used to access the precise location of the User’s device. The Application may collect, use and share the User’s location data for the purpose of providing location-based services.  Localization is not continuously activated.</p>
          <p>&nbsp;</p>
          <h3 style="text-align: left;"><strong>CONTACT</strong></h3>
          <p style="text-align: left;">If you have any questions or comments about this Policy or your personal information, you may contact our Privacy Compliance Manager by mail or email in order to request access or correction, to exercise any applicable rights, to file a complaint, or to obtain information about our policies and practices regarding service providers outside of Canada:</p>
          <p style="text-align: left;"><strong>OPTEL Vision Inc.</strong><br />
          To the Digital Privacy Manager<br />
          By mail:<br />
          <a href="/cdn-cgi/l/email-protection#1d6d6f746b7c7e645d726d6978717a6f72686d337e7270"><span class="__cf_email__" data-cfemail="8afaf8e3fcebe9f3cae5fafeefe6edf8e5fffaa4e9e5e7">[email&#160;protected]</span></a></p>
          <p style="text-align: left;">By mail:<br />
          2680, boul. du Parc Technologique<br />
          Québec (QC) G1P 4S6<br />
          CANADA</p>
      </TabPanel>
    </TabView>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  mounted() {
    
  }
}
</script>
